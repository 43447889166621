.pdf-viewer-container {
  display: flex;
  justify-content: center;
  margin: 10px;
  padding: 10px 15px;
  position: relative;
  min-height: 87vh;
  max-height: 87vh;
  background-color: white;
}

.pdf-viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.pdf-viewer iframe {
  width: 100%;
  height: 80vh;
}

.pdf-viewer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 5px 10px;
}

.pdf-viewer-header .pdf-document-title {
  color: rgb(63, 60, 60);
  font-size: 15px;
  font-weight: 600;
}

.view-pdf-doc-icon {
  display: flex;
  gap: 15px;
}

.edit-doc-btn svg {
  font-size: 22px;
  color: rgb(63, 60, 60);
  cursor: pointer;
}

.pdf-viewer-icon svg {
  font-size: 22px;
  color: rgb(63, 60, 60);
  cursor: pointer;
}

.pdf-details-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  min-width: 30%;
  max-width: 30%;
  min-height: 87vh;
  max-height: 87vh;
  position: absolute;
  bottom: 0%;
  right: 0%;
  background-color: #fff;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 30%);
}

.hide-pdf-details-container {
  display: none;
}

.pdf-viewer-tabs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.pdf-viewer-tabs-header .pdf-viewer-tab-title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3vw;
  min-height: 5vh;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 700;
  letter-spacing: 0.08rem;
  background: #9a9ab18f;
  color: rgba(0, 0, 0, 0.678);
}

.pdf-viewer-tab-title svg {
  font-size: 20px;
  cursor: pointer;
}

.pdf-viewer-tabs-header .pdf-viewer-tab-title:hover {
  background-color: black;
  color: #fff;
}

.pdf-viewer-tab-title.pdf-viewer-active-tab-title {
  background-color: black;
  color: #fff;
}

.content_tab_actions {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 1rem 4px;
  color: #666;
  box-sizing: border-box;
}

.doc-detail-tabs {
  padding: 20px 1rem;
}

.document_details_wrapper .rows .row_key {
  font-weight: 600;
  color: #666;
  display: inline-block;
  width: 25%;
}

.show-pdf-editor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
}

.go-back-to-pdf-viewer {
  max-height: 2.3rem;
}

.go-back-to-pdf-viewer svg {
  font-size: 2.2rem;
  margin-right: 10px;
  margin-top: 2px;
  cursor: pointer;
}

.document-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px;
  border-bottom: 1px solid #b7b7c88f;
}

.document-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* height: 8vh; */
}

.document-details-title {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  letter-spacing: 0.03rem;
  /* color: rgb(63, 60, 60); */
}

.document-details-content {
  padding-top: 10px;
}

.document-details-data {
  display: inline-block;
  min-width: 150px;
  font-size: 0.9rem;
  /* color: #666; */
}

.document-details-data-value {
  /* display: inline-block; */
  /* min-width: 180px; */
  font-size: 0.9rem;
  /* color: #666; */
}

.document-details-loader {
  padding-top: 10px;
}

.document-version-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px;
  border-bottom: 1px solid #b7b7c88f;
}

.document-version-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* height: 8vh; */
}

.document-version-title {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  letter-spacing: 0.03rem;
  /* color: rgb(63, 60, 60); */
}

.document-version-content {
  padding-top: 10px;
}

.document-version-content::-webkit-scrollbar {
  display: none;
}

.document-version-data {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  justify-content: space-around;
  /* border-top: 1px solid #9a9ab18f; */
}

.document-version-basic-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9rem;
}

/* .document-version-basic-data .dropdown-icon svg {
  font-size: 20px;
} */

.document-version-detail-data {
  padding: 10px 0px;
  padding-bottom: 0px;
}

.document-version-detail-data-part {
  display: inline-block;
  min-width: 150px;
  font-size: 0.9rem;
  /* color: #666; */
}

.document-version-detail-data-value {
  font-size: 0.9rem;
}

.document-history-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px;
  /* border-bottom: 1px solid #b7b7c88f; */
}

.document-history-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* height: 8vh; */
}

.document-history-title {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  letter-spacing: 0.03rem;
  /* color: rgb(63, 60, 60); */
}

.document-history-content {
  position: relative;
  z-index: 0;
  padding: 10px 0px;
}

.document-history-content::before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  left: 20px;
}

.document-history-data {
  padding: 10px 15px;
  margin: 3px 15px;
  position: relative;
}

.document-history-data-title {
  font-size: 0.9rem;
  font-weight: 600;
}

.document-history-data-date {
  font-size: 0.9rem;
}

.document-history-data::after {
  content: "";
  width: 10px;
  height: 10px;
  background: #2b93c8d0;
  border-radius: 50%;
  position: absolute;
  left: -9px;
  top: 32%;
  transform: translateY(-50%);
}

.pdf-details-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0%;
  background-color: white;
  gap: 10px;
  padding: 0px 15px;
  min-height: 10vh;
  z-index: 1000;
  /* height: 8vh; */
  /* border-bottom: 1px solid #b7b7c88f; */
  box-shadow: 0 2px 0px #b7b7c88f;
}

.pdf-details-header-icon {
  background-color: #d8d8e48f;
  color: #000000ad;
  padding: 8px;
  /* padding: 4px; */
  border-radius: 15%;
}

.pdf-details-header-icon svg {
  font-size: 25px;
  /* font-size: 20px; */
}

.pdf-details-header-title {
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.04rem;
  color: black;
  /* color: rgb(63, 60, 60); */
}

.close-pdf-details-container-icon {
  color: rgb(63, 60, 60);
  position: absolute;
  right: 15px;
}

.close-pdf-details-container-icon svg {
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-height: 700px) {
  .pdf-viewer-container {
    display: flex;
    justify-content: center;
    margin: 6px 10px;
    padding: 6px 15px;
    min-height: 86vh;
    max-height: 86vh;
    background-color: white;
  }

  .pdf-viewer iframe {
    width: 100%;
    height: 79vh;
  }

  .pdf-details-container {
    min-height: 86vh;
    max-height: 86vh;
  }

  .show-pdf-editor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
  }
}
