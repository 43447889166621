.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80vh;
  height: auto;
  width: 60%;
  padding: 0px;
  box-shadow: 0px 0px 5px 0 #ccc;
  border-radius: 6px;
  background: #fff;
}

.login-remember-me {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.login-remember-me:hover {
  color: #007bff;
}

.login-new-user {
  margin: 20px 0px;
  text-align: center;
}

.error-message {
  color: red;
  margin-top: -12px;
  margin-bottom: 10px;
}

.go-to-register-form {
  color: black;
  text-decoration: none;
  font-weight: 600;
}

.go-to-register-form:hover {
  color: blue;
  text-decoration: none;
}

.go-to-password-reset-form {
  color: black;
  text-decoration: none;
}

.go-to-password-reset-form:hover {
  color: blue;
  text-decoration: none;
}
.illustration_wrapper img {
  width: 60%;
}
.illustration_wrapper {
  width: 40%;
}
.login_form_wrapper {
  width: 45%;
  padding: 2rem;
  margin: 0 auto;
  text-align: start;
}
.illustration_wrapper {
  width: 40%;
  background-size: cover;
  min-height: 80vh;
  height: auto;
  border-radius: 6px 0 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_btn button {
  width: 100%;
  height: 49px;
  margin-top: 10px;
}
.login_btn button {
  width: 100%;
  height: 49px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
}
.login_form_wrapper h2 {
  text-align: center;
}
.login_form_wrapper .MuiFormControl-root.MuiFormControl-fullWidth {
  height: auto;
}
