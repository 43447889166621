.dashboard-container {
  min-width: calc(100vw - 220px);
}

.navbar {
  padding: 0px;
}

.dashboard-page-content {
  max-height: 95vh;
  overflow-y: scroll;
}

/* .dashboard-page-content::-webkit-scrollbar {
  width: 10px;
}

.dashboard-page-content::-webkit-scrollbar-track {
  background: #c7cdcfe6;
  border-radius: 0px;
}

.dashboard-page-content::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 0px;
} */

/* .dashboard-page-content::-webkit-scrollbar {
  width: 0.6em;
}

.dashboard-page-content::-webkit-scrollbar-track {
  margin: 8px 0px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgb(128, 123, 123);
}

.dashboard-page-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(13, 116, 207, 1);
  outline: 1px solid slategrey;
} */

.dahboard-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin: 0px 10px;
  gap: 10px;
  margin-top: 10px;
}

.dashboard-data {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  min-width: 48%;
  background: #fff;
}

.box-part-one {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 35%;
}

.box-part-two {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 35%;
}

.dashboard-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  align-content: center;
  background-color: #fff;
  border-radius: 3px;
  min-width: 220px;
  max-width: 220px;
  margin: 10px;
  padding: 10px;
  background-position: left bottom;
  border: 1px solid #b7b7c88f;
}

.dashboard-stats:hover {
  background-color: #607d8b29;
  transition: 0.3s;
}

.dashboard-stats .data-count {
  display: flex;
  justify-content: center;
  color: #007bff;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 1.5rem;
}

.data-with-icon {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* color: #666; */
  color: #000000ad;
}

.data-with-icon svg {
  font-size: 20px;
}

.data {
  margin-left: 5px;
  text-align: center;
  font-size: 16px;
  color: #000000ad;
  margin-top: 2px;
  font-weight: 600;
  letter-spacing: 0.07rem;
}

.piechart {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0px;
  padding: 10px 30px;
  margin-top: 0px;
  min-width: 51%;
}

.dashboard-column-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 70vw;
  max-width: 70vw; */
  min-height: 50vh;
  max-height: 50vh;
  padding: 10px 100px;
  background: #fff;
  border-radius: 0;
  margin: 12px;
}
