.link-popup-container {
  position: relative;
}

.single-doc-link-popup-content {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 30%);
  padding: 10px;
}

.single-doc-link-popup-content .select-doc-select-ui {
  margin-top: 10px;
}

.single-doc-link-popup-content .select-doc-select-ui .MuiOutlinedInput-root {
  min-width: 350px;
  max-width: 350px;
  min-height: 35px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.single-doc-link-popup-content .select-doc-select-ui .MuiInputLabel-root {
  margin-top: -9px;
}

.single-doc-link-popup-content button {
  background-color: yellow;
}

.single-doc-link-popup-content .select-doc-select-ui .Mui-focused {
  margin-top: 0px;
}

.single-doc-link-popup-content .select-doc-select-ui .MuiFormLabel-filled {
  margin-top: 0px;
}

.single-doc-link-tags-input-container {
  min-width: 350px;
  max-width: 350px;
  border: 2px solid rgb(214, 208, 208);
  padding: 0.5em;
  border-radius: 3px;
  margin-bottom: 10px;
}

.single-doc-link-tags-input-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.single-doc-link-tag-item {
  background-color: rgb(218, 216, 216);
  display: flex;
  align-items: center;
  /* height: 35px; */
  padding: 0.5em 0.75em;
  border-radius: 2px;
}

.link-popup-action-btn {
  display: flex;
  justify-content: flex-end;
}

.loading-link-popup-data {
  font-size: 1rem;
}

.no-doc-link-popup-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}
