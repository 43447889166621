*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.table-content {
  /* width: 100%; */
  max-height: 71vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-right: 4px;
}

.resized-table-content {
  max-height: calc(71vh - 40px);
  margin-right: 4px;

  overflow-y: scroll;
  scroll-behavior: smooth;
}

.trash-doc-table-content {
  /* width: 100%; */
  max-height: 71vh;
  overflow-y: scroll;
  /* margin-right: 17px; */
}

.documents-table {
  position: relative;
  min-width: 99%;
  border-collapse: collapse;
  margin-left: 10px;
  font-size: 13px;
  box-shadow: 0 3px 3px #e1e5ee;
  background-color: white;
}

.documents-table-header tr {
  box-shadow: 0 3px 3px #e1e5ee;
  height: 45px;
}

.documents-table td,
.documents-table th {
  padding: 8px 10px;
  text-align: center;
}

.documents-table .documents-table-header th {
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  font-size: 12px;
  font-weight: 600;
}

.documents-table .documents-table-header {
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: white;
}

.documents-table .document-table-body-row {
  position: relative;
}

.documents-table td {
  font-weight: 500;
  letter-spacing: 0.04rem;
}

.documents-table-body tr {
  border-bottom: 1px solid #edeff2;
  height: 60px;
}

.documents-table .documents-table-header th:first-child {
  padding-left: 23px;
}

.documents-table .documents-table-body td:last-child button {
  padding: 7px 5px;
  min-width: 24px;
  background-color: #d8d8e48f;
  color: #000000ad;
  border-radius: 3px;
  border: none;
}

.documents-table td:last-child svg {
  font-size: 16px;
}

.documents-table .documents-table-body .file-icon svg {
  width: 20px;
}

.documents-table .documents-table-body .category {
  margin: 0;
  padding: 6px;
  border-radius: 50px;
  text-align: center;
  color: #666;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
}

.documents-table .documents-table-body td:last-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 60px;
}

.table-content::-webkit-scrollbar {
  width: 10px;
}

.table-content::-webkit-scrollbar-track {
  background: #c7cdcfe6;
  border-radius: 6px;
}

.table-content::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 6px;
}

.resized-table-content::-webkit-scrollbar {
  width: 10px;
}

.resized-table-content::-webkit-scrollbar-track {
  background: #c7cdcfe6;
  border-radius: 6px;
}

.resized-table-content::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 6px;
}

.dropdown-button {
  cursor: pointer;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  min-width: 400px;
  max-width: 800px;
  border-radius: 4px;
  border: 2px solid #007bffbf;
}

.edit-document-form .field .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.popup_submit_btn {
  margin: 0 auto;
}

.popup_submit_btn button {
  border-radius: 5px;
  padding: 6px 26px;
  background: #007bff;
}

.edit-doc-modal {
  display: flex;
  align-items: center;
  align-content: center;
}

.popup-header-svg-icon {
  background-color: #007bff;
  color: #fff;
  padding: 0px 6px;
  border-radius: 3px;
}

.popup-header-title {
  color: #007bff;
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
}

.popup-header-svg-icon svg {
  font-size: 18px;
  margin-top: -2px;
}

#customized-dialog-title button {
  margin-top: 5px;
  color: #007bff;
}

.elements {
  position: absolute;
  top: 35px;
  left: 20px;
  height: 68px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0.6rem 0 rgb(0 0 0 / 30%);
}

.doc-elements {
  position: absolute;
  top: 22px;
  left: 0px;
  height: 32px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0.6rem 0 rgb(0 0 0 / 30%);
}

.dropdown-button .docs-dropdown-list .docs-dropdown-item {
  list-style: none;
  padding: 8px 14px;
}

.dropdown-button .docs-dropdown-list .docs-dropdown-item:hover {
  background: rgba(235, 235, 235, 0.893);
  color: #000;
}

.document-table-select-row {
  display: inline-block;
}

.bulk-func {
  display: flex;
  position: relative;
}

.document-table-sorting {
  margin-left: 3px;
}

.edit-document-form .field .input-field label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.edit-document .field .input-field .MuiFormControl-root .MuiOutlinedInput-root {
  min-height: 35px;
  min-width: 360px;
  max-width: 360px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.edit-document-form .field .input-field .Mui-focused {
  margin-top: 0px;
}

.edit-document-form .field .input-field .MuiFormLabel-filled {
  margin-top: 0px;
}

.edit-doc-select-option .MuiOutlinedInput-root {
  min-width: 360px;
  max-width: 360px;
  min-height: 35px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.edit-doc-select-option .Mui-focused {
  margin-top: 20px;
}

.edit-doc-select-option .MuiFormLabel-filled {
  margin-top: 20px;
}

/* tr:nth-child(even) {
  background-color: #f4f6fb;
} */

.star-checkbox {
  visibility: hidden;
  font-size: 23px;
  cursor: pointer;
}

.star-checkbox:before {
  position: relative;
  top: -10px;
  content: "\2606";
  color: black;
  visibility: visible;
}

.star-checkbox:checked:before {
  content: "\2605";
  color: yellow;
  visibility: visible;
}

/* .documents-table .other-tables-header th:first-child,
.documents-table .other-tables-body td:first-child {
  text-align: center;
  padding-left: 0px;
} */

.documents-table .starred-tables-header th:first-child,
.documents-table .starred-tables-body td:first-child {
  text-align: center;
  padding-left: 15px;
}

/* .documents-table .category-tables-header th:nth-child(3),
.documents-table .category-tables-body td:nth-child(3) {
  padding-left: 55px;
} */

.category-table-content {
  width: 100%;
  max-height: 80vh;
  padding-right: 7px;
}

.documents-table th:nth-child(5),
.documents-table td:nth-child(5) {
  text-align: start;
}

.documents-table th:nth-child(6),
.documents-table td:nth-child(6) {
  width: 120px;
}

.user-tables th:nth-child(5),
.user-tables td:nth-child(5) {
  text-align: center;
}

.recent-tables th:nth-child(3),
.recent-tables td:nth-child(3) {
  text-align: start;
}

.recent-tables th:nth-child(5),
.recent-tables td:nth-child(5) {
  text-align: center;
}

.recent-tables th:nth-child(4),
.recent-tables td:nth-child(4) {
  width: 120px;
}

.starred-tables th:nth-child(4),
.starred-tables td:nth-child(4) {
  text-align: start;
}

.starred-tables th:nth-child(5),
.starred-tables td:nth-child(5) {
  text-align: center;
  width: 120px;
}

.category-tables th:nth-child(2),
.category-tables td:nth-child(2),
.category-tables th:nth-child(3),
.category-tables td:nth-child(3) {
  text-align: start;
  /* padding-left: 25px; */
}

.category-tables th:nth-child(5),
.category-tables td:nth-child(5) {
  text-align: center;
}

.category-tables th:nth-child(1),
.category-tables td:nth-child(1) {
  width: 40px;
  text-align: start;
  padding-left: 23px;
}

.user-tables th:nth-child(1),
.user-tables td:nth-child(1) {
  text-align: start;
  padding-left: 23px;
}

.user-tables th:nth-child(2),
.user-tables td:nth-child(2) {
  text-align: start;
}

/* .user-tables th:nth-child(3),
.user-tables td:nth-child(3) {
  text-align: start;
} */

.category-tables th:nth-child(4),
.category-tables td:nth-child(4) {
  text-align: center;
  width: 120px;
}

.deleted-documents-tables th:nth-child(1),
.deleted-documents-tables td:nth-child(1) {
  width: 40px;
  text-align: start;
  padding-left: 23px;
}

/* .deleted-documents-tables th:nth-child(2),
.deleted-documents-tables td:nth-child(2) {
  width: 100px;
}

.deleted-documents-tables th:nth-child(3),
.deleted-documents-tables td:nth-child(3) {
  width: 80px;
} */

.deleted-documents-tables th:nth-child(4),
.deleted-documents-tables td:nth-child(4) {
  text-align: start;
  /* width: 200px; */
}

.deleted-documents-tables th:nth-child(6),
.deleted-documents-tables td:nth-child(6) {
  text-align: center;
}

.deleted-documents-tables th:nth-child(5),
.deleted-documents-tables td:nth-child(5) {
  width: 220px;
  padding: 0px 50px;
  text-align: center;
}

.deleted-category-tables th,
.deleted-category-tables td {
  text-align: start;
}

.deleted-category-tables th:nth-child(4),
.deleted-category-tables td:nth-child(4) {
  text-align: center;
  width: 120px;
}

.deleted-category-tables th:last-child,
.deleted-category-tables td:last-child {
  text-align: center;
}

.deleted-category-tables td:first-child {
  width: 40px;
  padding-left: 23px;
}

.deleted-users-tables th:nth-child(1),
.deleted-users-tables td:nth-child(1) {
  width: 40px;
  text-align: start;
  padding-left: 23px;
}

.deleted-users-tables th:nth-child(2),
.deleted-users-tables td:nth-child(2),
.deleted-users-tables th:nth-child(3),
.deleted-users-tables td:nth-child(3) {
  text-align: start;
}

.deleted-users-tables th:nth-child(4),
.deleted-users-tables td:nth-child(4),
.deleted-users-tables th:nth-child(5),
.deleted-users-tables td:nth-child(5),
.deleted-users-tables th:nth-child(6),
.deleted-users-tables td:nth-child(6) {
  text-align: center;
}

.case-doc-tables th:nth-child(1),
.case-doc-tables td:nth-child(1) {
  text-align: start;
  padding-left: 23px;
}

.case-doc-tables th:nth-child(2),
.case-doc-tables td:nth-child(2) {
  text-align: center;
}

.case-doc-tables th:nth-child(3),
.case-doc-tables td:nth-child(3) {
  text-align: start;
  flex-grow: 5;
}

.case-doc-tables th:nth-child(4),
.case-doc-tables td:nth-child(4) {
  text-align: start;
}

.case-doc-tables td:nth-child(4) .case-doc-table-category-cell {
  max-width: 160px;
  min-width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-doc-tables td:nth-child(4) .case-doc-table-category-cell-icon {
  font-size: 16px;
  cursor: pointer;
}

.case-doc-tables th:nth-child(5),
.case-doc-tables td:nth-child(5) {
  text-align: center;
}

.case-doc-tables th:nth-child(6),
.case-doc-tables td:nth-child(6) {
  text-align: center;
  width: 200px;
}

.case-doc-tables .request-doc-icon {
  position: relative;
  display: inline-block;
}

.case-doc-tables .request-doc-icon .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.747);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 130%;
  left: 88%;
  margin-left: -60px;
}

.case-doc-tables .request-doc-icon .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.747) transparent;
}

.case-doc-tables .request-doc-icon:hover .tooltiptext {
  visibility: visible;
}

.case-doc-tables .request-doc-request-icon svg {
  color: #ffd132;
  font-size: 25px;
}

.case-doc-tables .request-doc-approved-icon svg {
  color: #056f37;
  font-size: 25px;
}

.case-doc-tables .request-doc-upload-icon svg {
  color: green;
  font-size: 25px;
}

.case-doc-tables .request-doc-rejected-icon svg {
  color: red;
  font-size: 25px;
}

.case-doc-tables .documents-table-body td:last-child button {
  background-color: transparent;
  color: #000000ad;
}

.report-tables th,
.report-tables td {
  text-align: start;
}

.report-tables th:nth-child(1),
.report-tables td:nth-child(1) {
  text-align: start;
  padding-left: 23px;
}

.report-tables th:nth-child(7),
.report-tables td:nth-child(7) {
  text-align: center;
}

.case-list-tables th,
.case-list-tables td {
  text-align: start;
}

.case-list-tables th:nth-child(1),
.case-list-tables td:nth-child(1) {
  text-align: center;
  padding-left: 23px;
}

.case-list-tables td:nth-child(1) {
  color: rgb(0, 15, 181);
}

.case-list-tables th:nth-child(6),
.case-list-tables td:nth-child(6) {
  text-align: center;
}

.all-doc-tables th:nth-child(1),
.all-doc-tables td:nth-child(1) {
  text-align: center;
  padding-left: 23px;
}

.all-doc-tables th:nth-child(3),
.all-doc-tables td:nth-child(3),
.all-doc-tables th:nth-child(4),
.all-doc-tables td:nth-child(4),
.all-doc-tables th:nth-child(5),
.all-doc-tables td:nth-child(5),
.all-doc-tables th:nth-child(6),
.all-doc-tables td:nth-child(6) {
  text-align: center;
}

.all-doc-tables th:nth-child(6),
.all-doc-tables td:nth-child(6) {
  width: 160px;
}

.all-doc-tables th:nth-child(2),
.all-doc-tables td:nth-child(2) {
  text-align: start;
}

.case-doc-table-category-cell
  .category-cell-doc-select-ui
  .MuiOutlinedInput-root {
  min-width: 120px;
  max-width: 120px;
  min-height: 25px;
  max-height: 25px;
  /* letter-spacing: 0.02rem; */
  background-color: #ffffff9a;
  color: #000000d8;
}

.case-doc-table-category-cell .category-cell-doc-select-ui .Mui-focused {
  margin-top: 10px;
}

.case-doc-table-category-cell
  .category-cell-doc-select-ui
  .MuiFormLabel-filled {
  margin-top: 6px;
}
