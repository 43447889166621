body {
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  background-color: #a3daea57;
}

.app {
  background-color: #a3daea57;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: inherit !important;
}

.dms-pages {
  display: flex;
  flex-direction: row;
  max-height: 100vh;
  margin: 0%;
  padding: 0%;
}
