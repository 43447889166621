.upload-file-in-all-docs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upload-file-in-all-docs .uploaded-doc-textfield .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 365px;
  max-width: 365px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.upload-file-in-all-docs label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.upload-file-in-all-docs .Mui-focused {
  margin-top: 0px;
}

.upload-file-in-all-docs .MuiFormLabel-filled {
  margin-top: 0px;
}

.upload-file-in-all-docs .uploaded-doc-textfield-two .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 365px;
  max-width: 365px;
  margin-top: 10px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.upload-file-in-all-docs .uploaded-doc-textfield-two label {
  margin-top: 2px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.upload-file-in-all-docs .uploaded-doc-textfield-two .Mui-focused {
  margin-top: 10px;
}

.upload-file-in-all-docs .uploaded-doc-textfield-two .MuiFormLabel-filled {
  margin-top: 10px;
}

.upload-file-in-all-docs .select-doc-select-ui .MuiOutlinedInput-root {
  min-width: 365px;
  max-width: 365px;
  min-height: 35px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.upload-file-in-all-docs .select-doc-select-label {
  margin-top: 3px;
}

.upload-file-in-all-docs .select-doc-select-ui .Mui-focused {
  margin-top: 11px;
}

.upload-file-in-all-docs .select-doc-select-ui .MuiFormLabel-filled {
  margin-top: 11px;
}
