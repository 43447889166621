.case-doc-container {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - 220px);
  padding: 0px;
}

/* .navbar {
    display: flex;
    background-color: black;
  
    max-height: 6vh;
  } */

.case-doc-table {
  padding: 0px;
}

.case-doc-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}

.case-doc-table-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  min-height: 52px;
  max-height: 52px;
  padding: 10px;
  background-color: #fff;
  margin: 8px 17px 8px 10px;
}

.all-doc-table-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  min-height: 52px;
  max-height: 52px;
  padding: 10px;
  background-color: #fff;
  margin: 8px 17px 8px 10px;
}

.all-document-pdf-viewer {
  display: flex;
  flex-direction: column;
  min-height: 88vh;
  max-height: 88vh;
  padding: 10px;
  background-color: #fff;
  margin: 8px 17px 8px 10px;
}

.all-document-pdf-viewer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 4px;
  border-bottom: 1px solid black;
}

.all-document-pdf-viewer-title {
  color: rgb(63, 60, 60);
  font-size: 16px;
  font-weight: 600;
}

.all-document-pdf-viewer-icon {
  font-size: 22px;
  color: rgb(63, 60, 60);
  cursor: pointer;
}

.document-viewer-with-from {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 10px;
  margin-top: 10px;
}

.case-doc-table-header-part {
  display: flex;
  align-items: center;
}

.case-doc-textfield .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 30vw;
  max-width: 30vw;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.case-doc-requested-select-ui .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 130px;
  max-width: 130px;
  margin-right: 10px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.case-doc-select-ui .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 200px;
  max-width: 200px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.hello .MuiInputLabel-root {
  margin-top: 10px;
}

.case-doc-table-header .MuiOutlinedInput-root svg {
  width: 35px;
}
/* .case-doc-table-header .MuiInputLabel-root {
    margin-top: -0px;
  } */

.case-doc-table-header .MuiOutlinedInput-input {
  margin-left: 4px;
}

.case-doc-table-header label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.case-doc-table-header .Mui-focused {
  margin-top: 0px;
}

.case-doc-table-header .MuiFormLabel-filled {
  margin-top: 0px;
}

.case-doc-select-ui .Mui-focused {
  margin-top: 10px;
}

.case-doc-select-ui .MuiFormLabel-filled {
  margin-top: 10px;
}

.case-doc-requested-select-ui .Mui-focused {
  margin-top: 10px;
}

.case-doc-requested-select-ui .MuiFormLabel-filled {
  margin-top: 10px;
}

.case-doc-page-title {
  display: flex;
  letter-spacing: 0.07rem;
  color: #007bff;
  font-size: 1.2rem;
  font-weight: 600;
}

.add-starred {
  justify-content: flex-end;
  padding: 6px 12px;
  background: #f4f4ef;
  border: none;
  border-radius: 6px;
  font-weight: 900;
  font-size: 15px;
  color: #666;
  cursor: pointer;
}

.no-case-doc-data {
  margin-left: 10px;
  margin-right: 17px;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.05rem;
  background-color: white;
  text-align: center;
  padding: 10px 0px;
}

.case-doc-table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 17px;
  font-size: 0.8rem;
}

.case-doc-pagination {
  width: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  list-style: none;
  cursor: pointer;
}

.case-doc-pagination a {
  padding: 5px 10px;
  /* margin: 0px 7px;
    border-radius: 5px; */
  border: 1px solid #d8d8e4df;
  color: rgba(13, 116, 207, 1);
  background-color: white;
}

.case-doc-pagination a:hover {
  color: white !important;
  background: rgba(13, 116, 207, 1);
}

.case-doc-pagination-link {
  font-weight: bold;
}

.case-doc-pagination-link-active a {
  color: #fff;
  background-color: rgba(13, 116, 207, 1);
}

.case-doc-pagination-link-disabled a {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}

.case-doc-pagination-link-disabled a:hover {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}
