.category-container {
  display: flex;
  flex-direction: column;
  min-width: calc(100vw - 220px);
  padding: 0px;
}

.category-table {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.category-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}

.category-table-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: relative;
  min-height: 52px;
  max-height: 52px;
  padding: 10px;
  background-color: #fff;
  margin: 10px 17px 10px 10px;
}

.category-table-header .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 50vw;
  max-width: 50vw;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.category-table-header .MuiOutlinedInput-root svg {
  width: 35px;
}
/* .category-table-header .MuiInputLabel-root {
  margin-top: -0px;
} */

.category-table-header .MuiOutlinedInput-input {
  margin-left: 4px;
}

.category-table-header label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.category-table-header .Mui-focused {
  margin-top: 0px;
}

.category-table-header .MuiFormLabel-filled {
  margin-top: 0px;
}

.category-page-title {
  display: flex;
  color: #007bff;
  letter-spacing: 0.07rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.add-category {
  letter-spacing: 0.04rem;

  justify-content: flex-end;
  padding: 6px 12px;
  background-color: #d8d8e48f;
  color: #000000ad;
  border: none;
  border-radius: 6px;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
}

.category-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 12px;
  padding: 10px;
  padding-top: 3px;
  animation: filter 0.2s ease-in;
}

.hide-category-filter {
  display: none;
}

@keyframes filter {
  from {
    margin-top: -8px;
  }
  to {
    margin-top: 0px;
  }
}

.category-filter label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.category-filter .Mui-focused {
  margin-top: 0px;
}

.category-filter .MuiOutlinedInput-root {
  min-height: 35px;
  min-width: 154px;
  max-width: 154px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.category-filter .MuiFormLabel-filled {
  margin-top: 0px;
}

.no-category-data {
  margin-left: 10px;
  margin-right: 17px;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.05rem;
  background-color: white;
  text-align: center;
  padding: 10px 0px;
}

.category-table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  font-size: 0.8rem;
}

.category-pagination {
  width: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  list-style: none;
  cursor: pointer;
}

.category-pagination a {
  padding: 5px 10px;
  /* margin: 0px 7px;
  border-radius: 5px; */
  border: 1px solid #d8d8e4df;
  color: rgba(13, 116, 207, 1);
  background-color: white;
}

.category-pagination a:hover {
  color: white !important;
  background: rgba(13, 116, 207, 1);
}

.category-pagination-link {
  font-weight: bold;
}

.category-pagination-link-active a {
  color: #fff;
  background-color: rgba(13, 116, 207, 1);
}

.category-pagination-link-disabled a {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}

.category-pagination-link-disabled a:hover {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}
