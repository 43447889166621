.tags-input-container {
  min-width: 600px;
  max-width: 600px;
  width: 600px;
  min-height: 200px;
  max-height: 200px;
  border: 2px solid rgb(214, 208, 208);
  padding: 0.5em;
  border-radius: 3px;
  overflow-y: scroll;
}

.tags-input-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: flex;
  align-items: center;
  /* height: 35px; */
  padding: 0.5em 0.75em;
  border-radius: 2px;
}

.tag-item .close {
  /* background-color: rgb(48, 48, 48); */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  height: 35px;

  padding: 0.5em 0;
  border: none;
  outline: none;
}

.request-doc-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.request-doc-top-section .requested-doc-textfield .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 200px;
  max-width: 200px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.request-doc-top-section label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.request-doc-top-section .Mui-focused {
  margin-top: 0px;
}

.request-doc-top-section .MuiFormLabel-filled {
  margin-top: 0px;
}

.request-doc-top-section .request-doc-select-ui .MuiOutlinedInput-root {
  min-width: 150px;
  max-width: 150px;
  min-height: 35px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.request-doc-top-section .request-doc-select-ui .Mui-focused {
  margin-top: 11px;
}

.request-doc-top-section .request-doc-select-ui .MuiFormLabel-filled {
  margin-top: 11px;
}
