.dropdown-content {
  position: absolute;
  color: black;
  top: 33px;
  right: -10px;
  min-width: max-content;
  background: #fff;
  padding: 2px 0px;
  border-radius: 0px;
  box-shadow: 0 0 0.6rem 0 rgb(0 0 0 / 30%);
  z-index: 10000;
}

.navbar-dropdown {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.navbar-dropdown li {
  padding: 5px 10px;
  font-weight: 500;
  letter-spacing: 0.03rem;
}

.dropdown-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 2px 10px 0px 2px; */
  text-decoration: none;
  cursor: pointer;
  color: black;
  gap: 6px;
}

.dropdown-links-logout:hover {
  color: #007bff !important;
}

.clicked {
  display: none;
}
