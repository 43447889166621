.share-doc-select-ui .MuiOutlinedInput-root {
  min-width: 360px;
  max-width: 360px;
  min-height: 35px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.share-doc-select-ui .Mui-focused {
  margin-top: 10px;
}

.share-doc-select-ui .MuiFormLabel-filled {
  margin-top: 10px;
}

.share-doc-sms-option {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.share-doc-sms-option .share-textfield label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.share-doc-sms-option .share-textfield .MuiOutlinedInput-root {
  min-height: 35px;
  min-width: 360px;
  max-width: 360px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.share-doc-sms-option .share-textfield .Mui-focused {
  margin-top: 0px;
}

.share-doc-sms-option .share-textfield .MuiFormLabel-filled {
  margin-top: 0px;
}
