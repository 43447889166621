.documents-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
}

.table-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  min-height: 52px;
  max-height: 52px;
  padding: 10px;
  background-color: #fff;
  margin: 8px 17px 8px 10px;
}

.table-header .MuiOutlinedInput-root {
  min-height: 35px;
  max-height: 35px;
  min-width: 50vw;
  max-width: 50vw;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.table-header .MuiOutlinedInput-root svg {
  width: 35px;
}

.table-header .MuiOutlinedInput-input {
  margin-left: 4px;
}

.table-header label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.table-header .Mui-focused {
  margin-top: 0px;
}

.table-header .MuiFormLabel-filled {
  margin-top: 0px;
}

.doc-header-btn {
  display: flex;
  gap: 10px;
}

.filter-documents,
.add-document {
  padding: 6px 12px;
  /* background: #d3dcdfb0;
  background-color: #007bff9d; */
  background-color: #d8d8e48f;
  letter-spacing: 0.04rem;
  color: #000000ad;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.document-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 12px;

  /* justify-content: center; */
  /* background: white; */
  /* box-shadow: 0px 0px 15px #d8d8e48f; */
  /* border: 5px solid #d8d8e4df; */
  padding: 10px;
  padding-top: 3px;
  /* border-radius: 5px; */
  animation: filter 0.2s ease-in;
}

.document-filter::-webkit-scrollbar {
  display: none;
}

.hide-document-filter {
  animation: filter 0.5s ease-in;
  display: none;
}

@keyframes filter {
  from {
    margin-top: -8px;
  }
  to {
    margin-top: 0px;
  }
}

.document-filter label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.document-filter .Mui-focused {
  margin-top: 0px;
}

.document-filter .MuiOutlinedInput-root {
  min-height: 35px;
  min-width: 154px;
  max-width: 154px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.document-filter .MuiFormLabel-filled {
  margin-top: 0px;
}

.no-doc-data {
  margin-left: 10px;
  margin-right: 17px;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.05rem;
  background-color: white;
  text-align: center;
  padding: 10px 0px;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 17px;
  font-size: 0.8rem;
}

.doc-pagination {
  width: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  list-style: none;
  cursor: pointer;
}

.doc-pagination a {
  padding: 5px 10px;
  font-weight: 500;
  /* margin: 0px 7px;
  border-radius: 5px; */
  border: 0.1px solid #d8d8e4df;
  color: rgba(13, 116, 207, 1);
  background-color: white;
}

.doc-pagination a:hover {
  color: white !important;
  background: rgba(13, 116, 207, 1);
}

.doc-pagination-link {
  font-weight: bold;
}

.doc-pagination-link-active a {
  color: #fff;
  background-color: rgba(13, 116, 207, 1);
}

.doc-pagination-link-disabled a {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}

.doc-pagination-link-disabled a:hover {
  background-color: #d8d8e4df;
  color: #000000ad;
  border: 1px solid #d8d8e4df;
  cursor: not-allowed;
}

@media only screen and (max-height: 630px) {
  .table-header {
    min-height: 48px;
    max-height: 48px;
    padding: 10px;
    margin: 7px 17px 7px 10px;
  }

  .filter-documents,
  .add-document {
    padding: 6px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
  }
}
