.link-tags-input-container {
  min-width: 600px;
  max-width: 600px;
  min-height: 200px;
  max-height: 200px;
  border: 2px solid rgb(214, 208, 208);
  padding: 0.5em;
  border-radius: 3px;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.link-tags-input-content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.link-tag-item {
  background-color: rgb(218, 216, 216);
  display: flex;
  align-items: center;
  /* height: 35px; */
  padding: 0.5em 0.75em;
  border-radius: 2px;
}

.link-tag-item .close {
  /* background-color: rgb(48, 48, 48); */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.link-popup-content .select-doc-select-ui .MuiOutlinedInput-root {
  min-width: 600px;
  max-width: 600px;
  min-height: 35px;
  max-height: 35px;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.link-popup-content .select-doc-select-ui .Mui-focused {
  margin-top: 10px;
}

.link-popup-content .select-doc-select-ui .MuiFormLabel-filled {
  margin-top: 10px;
}
