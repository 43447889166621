.notification-content {
  position: absolute;
  color: black;
  top: 33px;
  right: -10px;
  background: #fff;
  /* padding: 12px 15px; */
  border-radius: 0px;
  /* white-space: nowrap; */
  min-width: 400px;
  max-width: 400px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0 0 0.6rem 0 rgb(0 0 0 / 30%);
  z-index: 10000;
}

.notification-content::-webkit-scrollbar {
  width: 8px;
}

.notification-content::-webkit-scrollbar-track {
  background: #c7cdcfe6;
  /* border-radius: 6px; */
}

.notification-content::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 6px;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0%;
  background-color: #fff;
  padding: 10px 15px;
  border-bottom: 1px solid rgb(150, 147, 147);
  /* border-bottom: 1px solid #b7b7c88f; */
}

.notification-heading {
  letter-spacing: 0.03rem;
  font-weight: 600;
  font-size: 0.85rem;
}

.dropdown-content::-webkit-scrollbar {
  display: none;
}

.no-notifications,
.loading-notification {
  min-width: 400px;
  max-width: 400px;
  min-height: 50px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.notification-with-icon {
  display: flex;
  padding: 12px 15px;
  width: 400px;
  overflow-wrap: break-word;
  border-bottom: 1px solid #b7b7c88f;
  cursor: default;
}

.notification-with-icon svg {
  cursor: default;
  font-size: 1.2rem;
  margin-top: -2px;
}

.notification-with-icon:hover {
  background-color: rgba(232, 231, 231, 0.848);
}

.notifications {
  font-size: 0.92rem;
  padding-left: 8px;
  letter-spacing: 0.01rem;
}
