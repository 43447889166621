.navbar-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  /* border-bottom: 1px solid #007bff; */
  box-shadow: 2px 0px 3px #007bff;
  color: black;
  margin: 0px;
  padding: 10px;
  min-height: 35px;
  max-height: 35px;
  width: 100vw;
}

.navbar-icon-container {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-right: 15px;
}

.dropdown {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  align-items: center;
  height: 4vh;
  margin: 0px 15px;
}

.dropdown svg {
  font-size: 20px;
  cursor: pointer;
}

.dropdown img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
}
